import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.45rem 1.0875rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  @media only screen and (max-width: 414px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`
